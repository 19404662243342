import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/smooth-doc/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Getting Started`}</h1>
    <p>{`Follow these few steps to get ready with `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "installation"
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Installation`}</h2>
    <p>{`Installing `}<inlineCode parentName="p">{`@loadable/component`}</inlineCode>{` only takes a single command and you're ready to roll:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @loadable/component
# or use yarn
yarn add @loadable/component
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`@loadable/babel-plugin`}</inlineCode>{` is required for `}<a parentName="p" {...{
          "href": "/docs/server-side-rendering/"
        }}>{`Server Side Rendering`}</a>{` and automatic chunk names generation. `}<inlineCode parentName="p">{`@loadable/server`}</inlineCode>{` and `}<inlineCode parentName="p">{`@loadable/webpack-plugin`}</inlineCode>{` are only required for `}<a parentName="p" {...{
          "href": "/docs/server-side-rendering/"
        }}>{`Server Side Rendering`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "split-your-first-component"
    }}><a parentName="h2" {...{
        "href": "#split-your-first-component",
        "aria-hidden": true,
        "className": "anchor"
      }}><svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>{`Split your first component`}</h2>
    <p>{`Loadable lets you render a dynamic import as a regular component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import loadable from '@loadable/component'

const OtherComponent = loadable(() => import('./OtherComponent'))

function MyComponent() {
  return (
    <div>
      <OtherComponent />
    </div>
  )
}
`}</code></pre>
    <p>{`That's it, `}<inlineCode parentName="p">{`OtherComponent`}</inlineCode>{` will now be loaded in a separated bundle!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      